import {type SupportedLanguage as MFSupportedLanguage} from '@famly/mf_system_provider';
import makeLocalSetup, {defaultLocale, supportedLanguages, type SupportedLanguages} from 'web-app/shared/locale-setup';
import LocalStorageService from 'web-app/services/local-storage';
import {head, isNonEmpty} from 'web-app/util/non-empty-array';

const savedLocale = LocalStorageService.getFromLocalStorage('locale');

const defaultLocaleFromHostname = () => {
    const hostname = window.location.hostname;

    if (hostname.endsWith('.de')) {
        return 'de_DE';
    } else {
        return defaultLocale;
    }
};

export const getBrowserLanguage = () => {
    // Get supported language(s) from browser (IE doesn't support language_s_)
    const languages = window.navigator.languages ?? [window.navigator.language];

    // Search through available languages for one that is supported
    const supportedLanguages = languages.map(formatNavigatorLanguage).filter(isSupportedLanguage);

    // If more then none are found then return the first
    if (isNonEmpty(supportedLanguages)) {
        return head(supportedLanguages);
    }

    // Otherwise fall back to default
    return defaultLocaleFromHostname();
};

export const getModernFamlyLanguage = (locale: string): MFSupportedLanguage => {
    switch (locale) {
        case 'da_DK':
            return 'daDK';
        // Swiss dutch is not part of `SupportedLanguages`, se we need to check the string "manually" here
        case 'de_CH':
            return 'deCH';
        case 'de_DE':
            return 'deDE';
        case 'es_ES':
            return 'esES';
        case 'es_US':
            return 'esUS';
        case 'en_GB':
            return 'enGB';
        case 'en_US':
            return 'enUS';
        case 'no_NO':
            return 'nbNO';
        default:
            return 'enUS';
    }
};

export const isSupportedLanguage = (lang: string): lang is SupportedLanguages =>
    supportedLanguages.includes(lang as any);

const formatNavigatorLanguage = (lang: string) => lang.replace('-', '_');

export default makeLocalSetup(() => Promise.resolve({locale: getBrowserLanguage()}), savedLocale);
